@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --largeFromViewport (min-width: $tokenSizingBreakpointLarge1XTo);

.root {
  /* aspect-ratio not applicable since it does't have support in Safari 14 */
  & .wrapper {
    border-radius: $tokenBorderRadiusDefault;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    /* element to support ratio calculation */
    & div {
      padding-top: calc(5 / 6 * 100%);
    }

    & img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  & .name {
    font-size: $tokenTypographySupplementaryFontSize;
    line-height: $tokenTypographySupplementaryLineHeight;

    @media (--mediumFromViewport) {
      font-size: $tokenTypographyBodyDefaultFontSize;
      line-height: $tokenTypographyBodyDefaultLineHeight;
    }
  }

  & .description {
    color: $tokenColorsGreyscaleDark2X;
    font-size: $tokenFontSizesSmall2X;
    line-height: $tokenTypographySupplementaryLineHeight;

    @media (--mediumFromViewport) {
      font-size: $tokenTypographySupplementaryFontSize;
    }
  }
}
