@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  border-radius: $tokenBorderRadiusLarge2X;
  display: grid;
  grid-gap: $tokenSpacingSmall3X;
  grid-template-areas:
    'first second third'
    'first second fourth';
  grid-template-columns: 40% 40% 20%;

  /* Required to achieve the desired layout */
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {
    aspect-ratio: 1 / 1;
    cursor: pointer;
    height: auto;
    object-fit: cover;
    width: 100%;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  & .first {
    background-color: $tokenColorsGreyscaleLight4X;
    grid-area: first;
  }

  & .second {
    background-color: $tokenColorsGreyscaleLight4X;
    grid-area: second;
  }

  & .third {
    background-color: $tokenColorsGreyscaleLight4X;
    grid-area: third;
  }

  & .fourth {
    background-color: $tokenColorsGreyscaleLight4X;
    grid-area: fourth;
  }

  & .button {
    background-color: $tokenColorsGreyscaleWhite;
    border-radius: $tokenSpacingSmall2X;
    bottom: $tokenSpacingLarge1X;
    color: $tokenColorsGreyscaleDark4X;
    font-size: $tokenFontSizesSmall1X;
    padding: $tokenSpacingSmall3X $tokenSpacingSmall1X;
    position: absolute;
    right: $tokenSpacingLarge1X;

    &:hover,
    &:focus {
      background-color: $tokenColorsBrandTertiaryDefault;
    }
  }
}
