@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.isLiked {
  & button {
    color: $tokenColorsOtherFavourite;
  }
}

.isLikedAnimation {
  & button {
    color: $tokenColorsOtherFavourite;

    & svg {
      animation: activeAnimation 0.5s;
    }
  }
}

@keyframes activeAnimation {
  40% {
    transform: scale(0);
  }

  75% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
