@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.header {
  align-items: flex-end;
  display: flex;
  gap: $tokenSpacingDefault;
  justify-content: space-between;
  margin-bottom: $tokenSpacingLarge1X;

  & .description {
    padding-top: $tokenSpacingSmall3X;
  }

  & .priceInformation {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    & span {
      color: $tokenColorsGreyscaleDark2X;
      font-size: $tokenFontSizesSmall1X;
      line-height: $tokenLineHeightsSmall2X;
    }

    & .basePrice {
      color: $tokenColorsGreyscaleDark4X;
      font-size: $tokenFontSizesLarge4X;
      line-height: $tokenLineHeightsSmall2X;
    }
  }
}

.noDescription {
  align-items: center;
}
