@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  & .mainContent {
    display: flex;
    justify-content: space-between;

    & .serviceInformation {
      align-items: center;
      display: flex;

      & .serviceIcon {
        margin-right: $tokenSpacingDefault;
      }

      & .serviceTitleAndDescription {
        display: flex;
        flex-direction: column;

        & .serviceTitle {
          color: $tokenColorsGreyscaleDark3X;
          font-size: $tokenFontSizesSmall1X;

          @media (--breakpointMediumFrom) {
            font-size: $tokenFontSizesDefault;
          }
        }

        & .serviceDescription {
          color: $tokenColorsGreyscaleDark2X;
          font-size: $tokenTypographyFootnoteFontSize;

          @media (--breakpointMediumFrom) {
            font-size: $tokenTypographySupplementaryFontSize;
          }
        }
      }
    }

    & .priceInformation {
      display: flex;
      flex-direction: column;
      padding-left: $tokenSpacingDefault;
      text-align: right;
    }
  }

  & .divider {
    margin: $tokenSpacingDefault 0;
  }
}
