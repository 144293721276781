@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointExtraLargeFrom (min-width: $tokenSizingBreakpointLarge2XFrom);

.root {
  --zIndex: 999;
  --dropShadowBlur: $tokenSpacingSmall4X;
  --dropShadowSpread: calc($tokenSpacingSmall4X + $tokenSpacingSmall3X);

  align-items: center;
  animation: fadeInFromAbove 0.5s ease forwards;
  background-color: $tokenColorsGreyscaleWhite;
  /* stylelint-disable-next-line function-disallowed-list */
  box-shadow: 0 var(--dropShadowBlur) var(--dropShadowSpread) rgba(0 0 0 / 0.15);
  display: flex;
  justify-content: space-between;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--zIndex);

  & .contentWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    min-width: $tokenSizingBreakpointMediumFrom;
    padding: 0 $tokenSpacingLarge1X;
    width: 100%;

    @media (--breakpointLargeFrom) {
      padding: 0 calc($tokenSpacingLarge8X + $tokenSpacingDefault + $tokenSpacingSmall4X);
    }

    @media (--breakpointExtraLargeFrom) {
      max-width: $tokenSizingBreakpointMediumTo;
      padding: 0;
    }

    & .bookingDetailsContainer {
      align-items: center;
      display: flex;
    }

    & .priceInformation {
      align-items: baseline;
      display: flex;
      margin-right: $tokenSpacingDefault;

      & .from {
        font-size: $tokenFontSizesSmall1X;
        padding-right: $tokenSpacingSmall3X;
      }

      & .timeUnit {
        font-size: $tokenFontSizesSmall1X;
        padding-left: $tokenSpacingSmall3X;
      }
    }

    & .contactSitterButton {
      flex: 1;
      padding: $tokenSpacingDefault $tokenSpacingSmall2X $tokenSpacingDefault $tokenSpacingDefault;

      & button {
        text-align: center;
        width: 100%;
      }
    }
  }
}

@keyframes fadeInFromAbove {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
