@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: flex;
  flex-direction: column;

  & .mainContent {
    display: grid;
    grid-column-gap: $tokenSpacingDefault;
    grid-template-columns: 1fr 1fr;
  }

  & .sectionSubtitle {
    color: $tokenColorsGreyscaleDark2X;
    font-size: $tokenTypographySupplementaryFontSize;
    font-weight: $tokenTypographySupplementaryFontWeight;
    line-height: $tokenTypographySupplementaryLineHeight;
    padding-bottom: $tokenSpacingSmall2X;
  }

  & .homePropertyContainer {
    align-items: center;
    display: flex;
    padding-bottom: $tokenSpacingSmall2X;

    & svg {
      align-self: flex-start;
      display: flex;
      min-height: $tokenSizingElementIconSmall;
      min-width: $tokenSizingElementIconSmall;
      padding-top: calc($tokenSpacingSmall4X/2 + $tokenSpacingSmall4X/4);
    }

    & .homePropertyValue {
      font-size: $tokenTypographySupplementaryFontSize;
      padding-left: $tokenSpacingSmall2X;

      @media (--mediumFromViewport) {
        font-size: $tokenTypographyBodyDefaultFontSize;
      }
    }
  }
}
