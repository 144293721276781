@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingSmall2X;
  margin-bottom: $tokenSpacingDefault;
}

.icon {
  background-color: $tokenColorsBrandTertiaryDefault;
  border-radius: $tokenBorderRadiusLarge3X;
  padding: $tokenSpacingDefault;

  & svg {
    color: $tokenColorsOtherFavourite;
  }
}
