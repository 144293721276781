@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  background-color: $tokenColorsGreyscaleWhite;
  border-top: $tokenBorderRadiusSmall2X solid $tokenColorsGreyscaleLight4X;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: $tokenSpacingSmall2X $tokenSpacingDefault $tokenSpacingSmall2X $tokenSpacingDefault;
  position: fixed;
  right: 0;

  & .container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .priceInformation {
      display: flex;
      flex-direction: column;

      & .basePrice {
        font-size: $tokenFontSizesLarge1X;
        margin: calc($tokenSpacingSmall2X * -1) 0;
      }
    }

    & .contactSitterButton {
      flex: 1;
      margin: 0 $tokenSpacingDefault;

      & button {
        text-align: center;
        width: 100%;
      }
    }
  }

  & .notice {
    font-size: $tokenFontSizesSmall1X;
    margin-top: $tokenSpacingSmall3X;
    text-align: center;
  }
}
