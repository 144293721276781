@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --gap: $tokenSpacingDefault;

  & .items {
    display: grid;
    gap: var(--gap);
    grid-template-columns: 1fr 1fr 1fr;

    @media (--breakpointMediumFrom) {
      --gap: $tokenSpacingLarge1X;

      gap: var(--gap);
    }

    & .item {
      align-items: center;
      display: flex;
      flex-direction: column;

      & img {
        height: $tokenSpacingLarge6X;
        width: $tokenSpacingLarge6X;
      }

      & .label {
        font-size: $tokenFontSizesSmall1X;
        line-height: $tokenLineHeightsSmall1X;
        margin-top: $tokenSpacingSmall2X;
        text-wrap: nowrap;

        @media (--breakpointMediumFrom) {
          font-size: $tokenFontSizesDefault;
        }
      }

      & .description {
        color: $tokenColorsGreyscaleDark2X;
        font-size: $tokenFontSizesSmall2X;
        line-height: $tokenLineHeightsSmall1X;
        text-wrap: nowrap;

        @media (--breakpointMediumFrom) {
          font-size: $tokenTypographySupplementaryFontSize;
        }
      }
    }
  }

  & .divider {
    margin: $tokenSpacingDefault 0;
  }
}
