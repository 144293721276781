@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --backgroundColor: $tokenColorsBrandTertiaryDefault;

  background-color: var(--backgroundColor);
  border-radius: $tokenBorderRadiusDefault;
  margin-top: $tokenSpacingSmall1X;
  padding: $tokenSpacingDefault;

  & span {
    font-weight: $tokenFontWeightsSemiBold;
    line-height: $tokenLineHeightsDefault;
  }

  & div {
    padding-top: $tokenSpacingSmall2X;
  }

  & p {
    color: $tokenColorsGreyscaleDark3X;
    font-size: $tokenFontSizesSmall1X;
    font-weight: $tokenFontWeightsRegular;
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 $tokenSpacingSmall2X 0;
    }

    @media (--breakpointMediumFrom) {
      font-size: $tokenFontSizesDefault;
    }
  }

  & button {
    background-color: var(--backgroundColor);
    border: none;
    color: $tokenColorsBrandDefault;
    cursor: pointer;
    display: block;
    font-size: $tokenFontSizesSmall2X;
    height: $tokenSizingInteractionTapTarget;
    margin: 0 0 calc(-1 * $tokenSpacingDefault) auto;
  }
}

.withoutBackground {
  --backgroundColor: transparent;

  background-color: --var(--backgroundcolor);
  border-radius: 0;
  margin-top: 0;
  padding: 0;

  & button {
    background-color: --var(--backgroundcolor);
  }

  & div {
    padding-top: 0;
  }
}

.hasMaxWidth {
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 580px;
}
