@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  /* 300px from design */
  --minHeight: calc($tokenSpacingLarge8X * 3 + $tokenSpacingSmall1X);

  min-height: var(--minHeight);
  position: relative;

  @media (--breakpointLargeFrom) {
    /* 380px from design */
    --minHeight: calc($tokenSpacingLarge8X * 4 - $tokenSpacingSmall3X);
  }

  & img {
    border-radius: $tokenBorderRadiusDefault;
    margin: auto;
  }

  & .iconContainer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    & .iconCircle {
      align-items: center;
      background-color: $tokenColorsGreyscaleDark2X;
      border-radius: $tokenBorderRadiusLarge7X;
      display: flex;
      justify-content: center;
      padding: $tokenSpacingLarge1X;

      & svg {
        color: $tokenColorsGreyscaleWhite;
      }
    }
  }

  & .hide {
    display: none;
  }
}
