@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingLarge1X;

  & div {
    display: flex;
    flex-shrink: 0;
  }

  & .actions {
    gap: $tokenSpacingDefault;
  }

  & .main {
    align-items: center;
    justify-content: space-between;

    & .avatar {
      --avatarSide: $tokenSpacingLarge4X;
      --avatarMargin: $tokenSpacingSmall2X;
      --avatarBorderRadius: $tokenBorderRadiusSmall1X;

      border-radius: var(--avatarBorderRadius);
      float: left;
      height: var(--avatarSide);
      margin-right: var(--avatarMargin);
      overflow: hidden;
      width: var(--avatarSide);

      @media (--mediumFromViewport) {
        --avatarMargin: $tokenSpacingDefault;
        --avatarBorderRadius: $tokenBorderRadiusDefault;
      }

      & img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    & .sitterInformation {
      display: flex;
      flex-direction: column;

      & .firstRow {
        align-items: center;
        gap: $tokenSpacingSmall2X;
        margin-top: $tokenSpacingSmall2X;
      }

      & .secondRow {
        --ratingFontSize: $tokenTypographySupplementaryFontSize;
        --ratingLineHeight: $tokenTypographySupplementaryLineHeight;

        align-items: center;
        color: $tokenColorsGreyscaleDark3X;
        font-size: var(--ratingFontSize);
        font-weight: $tokenTypographySupplementaryFontWeight;
        gap: $tokenSpacingSmall2X;
        line-height: var(--ratingLineHeight);
        margin-bottom: $tokenSpacingSmall2X;

        @media (--mediumFromViewport) {
          --ratingFontSize: $tokenTypographyBodyDefaultFontSize;
          --ratingLineHeight: $tokenTypographyBodyDefaultLineHeight;
        }

        & .rating {
          align-items: center;
          flex-shrink: 0;
          gap: $tokenSpacingSmall4X;

          & svg {
            color: $tokenColorsOtherRating;
          }
        }

        & .area {
          flex-shrink: 0;
        }

        & .verticalDivider {
          --dividerHeight: calc($tokenSpacingLarge1X - $tokenSpacingSmall3X);

          background-color: $tokenColorsElementDivider;
          height: var(--dividerHeight);
          width: calc($tokenSpacingSmall4X / 2);

          @media (--mediumFromViewport) {
            --dividerHeight: $tokenSpacingLarge1X;
          }
        }
      }
    }
  }
}
