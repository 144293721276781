@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.list {
  display: grid;
  gap: $tokenSpacingDefault;
  grid-template-columns: 1fr 1fr;

  @media (--mediumFromViewport) {
    gap: $tokenSpacingLarge1X;
  }
}
