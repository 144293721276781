@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingLarge1X;

  & div {
    display: flex;
    flex-shrink: 0;
  }

  & .actions {
    gap: $tokenSpacingDefault;
  }

  & .main {
    --starColor: #f7c457;

    align-items: center;
    justify-content: space-between;

    & .data {
      display: block;
      gap: $tokenSpacingDefault;
      margin: calc($tokenSpacingSmall3X - ($tokenSpacingSmall4X / 4)) 0;
      max-height: $tokenSpacingLarge7X;

      & .firstRow,
      & .secondRow {
        align-items: center;
        gap: $tokenSpacingSmall2X;
        margin-bottom: $tokenSpacingSmall2X;
      }

      & .firstRow {
        margin-top: calc((-1 * (($tokenSpacingSmall3X - ($tokenSpacingSmall4X / 4))) / 2));

        & svg {
          color: $tokenColorsBrandDefault;
        }

        & .repeat {
          --repeatFontSize: $tokenTypographySupplementaryFontSize;

          background-color: $tokenColorsGreyscaleLight5X;
          border-radius: $tokenBorderRadiusLarge7X;
          color: $tokenColorsGreyscaleDark3X;
          font-size: var(--repeatFontSize);
          font-weight: $tokenTypographySupplementaryFontWeight;
          line-height: $tokenTypographySupplementaryLineHeight;
          padding: $tokenSpacingSmall3X $tokenSpacingSmall1X;

          @media (--mediumFromViewport) {
            --repeatFontSize: $tokenTypographyBodyDefaultFontSize;
          }
        }
      }

      & .secondRow {
        --ratingFontSize: $tokenTypographySupplementaryFontSize;
        --ratingLineHeight: $tokenTypographySupplementaryLineHeight;

        color: $tokenColorsGreyscaleDark3X;
        font-size: var(--ratingFontSize);
        font-weight: $tokenTypographySupplementaryFontWeight;
        line-height: var(--ratingLineHeight);

        @media (--mediumFromViewport) {
          --ratingFontSize: $tokenTypographyBodyDefaultFontSize;
          --ratingLineHeight: $tokenTypographyBodyDefaultLineHeight;
        }

        & .rating {
          align-items: center;
          flex-shrink: 0;
          gap: $tokenSpacingSmall4X;
          margin-right: calc(-1 * $tokenSpacingSmall3X);

          & svg {
            color: var(--starColor);
          }
        }

        & .area {
          flex-shrink: 0;
        }

        & .verticalDivider {
          --dividerHeight: calc($tokenSpacingLarge1X - $tokenSpacingSmall3X);

          background-color: $tokenColorsElementDivider;
          height: var(--dividerHeight);
          width: calc($tokenSpacingSmall4X / 2);

          @media (--mediumFromViewport) {
            --dividerHeight: $tokenSpacingLarge1X;
          }
        }
      }

      & .thirdRow {
        color: $tokenColorsGreyscaleDark2X;
        display: flex;
        font-size: $tokenTypographyFootnoteFontSize;
        font-weight: $tokenTypographyFootnoteFontWeight;
        gap: $tokenSpacingDefault;
        line-height: $tokenTypographyFootnoteLineHeight;
      }
    }
  }
}
