@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  & .dialogContainer {
    display: flex;
    flex-direction: row;

    & .dialogAvatar {
      --avatarSide: $tokenSpacingLarge4X;
      --avatarMargin: $tokenSpacingSmall2X;
      --avatarBorderRadius: $tokenBorderRadiusSmall1X;

      border-radius: var(--avatarBorderRadius);
      float: left;
      height: var(--avatarSide);
      margin-right: var(--avatarMargin);
      overflow: hidden;
      width: var(--avatarSide);

      @media (--mediumFromViewport) {
        --avatarMargin: $tokenSpacingDefault;
        --avatarBorderRadius: $tokenBorderRadiusDefault;
      }

      & img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  & .dialogLinks {
    display: flex;
    flex-direction: column;
    margin-top: $tokenSpacingLarge1X;

    & svg {
      margin-right: $tokenSpacingSmall2X;
    }

    & .socialShareLinks {
      margin-top: $tokenSpacingDefault;
    }

    & .anchorButton {
      --backgroundColor: $tokenColorsBrandTertiaryDefault;

      align-items: center;
      background-color: var(--backgroundColor);
      border-radius: $tokenBorderRadiusLarge4X;
      color: $tokenColorsBrandDefault;
      display: inline-flex;
      font-weight: $tokenFontWeightsSemiBold;
      justify-content: center;
      letter-spacing: $tokenLetterSpacingLarge1X;
      margin-bottom: $tokenSpacingDefault;
      padding: calc($tokenSpacingSmall2X - $tokenBorderWidthDefault)
        calc($tokenSpacingDefault - $tokenBorderWidthDefault);
      transition: $tokenMotionGlobalTransition;
      transition-property: background-color, $tokenMotionFocusRingProperty;
      width: 100%;

      &:hover {
        --backgroundColor: $tokenColorsBrandLight5X;
      }

      &:focus {
        --backgroundColor: $tokenColorsBrandTertiaryDefault;
      }

      &:active {
        --backgroundColor: $tokenColorsBrandLight4X;
      }

      &:disabled {
        --backgroundColor: $tokenColorsInteractionDisabledBackground;
      }
    }
  }
}
