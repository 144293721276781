@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --largeFromViewport (min-width: $tokenSizingBreakpointLarge1XTo);

.container {
  display: flex;
  flex-direction: column;
  margin-top: 0;

  @media (--mediumFromViewport) {
    margin: $tokenSpacingLarge1X auto 0;
    max-width: $tokenSizingLayoutGridMaxWidth;
    width: 100%;
  }

  & .wrapper {
    --sideMargin: $tokenSpacingLarge1X;

    margin-left: var(--sideMargin);
    margin-right: var(--sideMargin);

    @media (--mediumFromViewport) {
      --sideMargin: $tokenSpacingLarge1X;
    }
  }

  & .hiwContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: -$tokenSpacingDefault;

    & .anchorButtonHIW {
      font-weight: $tokenFontWeightsBold;
      text-align: center;
    }
  }
}
