@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  display: flex;
  flex-direction: column;
  gap: $tokenSpacingLarge1X;

  @media (--breakpointMediumFrom) {
    flex-direction: row;
  }

  @media (--breakpointLargeFrom) {
    gap: $tokenSpacingLarge3X;
  }

  & .section {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $tokenSpacingDefault;

    @media (--breakpointMediumFrom) {
      gap: $tokenSpacingLarge1X;
    }

    & .body {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: $tokenSpacingDefault;

      @media (--breakpointMediumFrom) {
        gap: $tokenSpacingLarge1X;
      }

      & svg {
        color: $tokenColorsGreyscaleDark2X;
      }

      & .bodyText {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: $tokenSpacingDefault;

        & p,
        & a {
          font-size: $tokenTypographySupplementaryFontSize;
          line-height: $tokenTypographySupplementaryLineHeight;

          @media (--breakpointMediumFrom) {
            font-size: $tokenTypographyBodyDefaultFontSize;
            line-height: $tokenTypographyBodyDefaultLineHeight;
          }
        }

        & .title {
          color: $tokenColorsGreyscaleDark4X;
          font-weight: $tokenFontWeightsMedium;
          margin-bottom: $tokenSpacingSmall3X;
        }
      }
    }
  }
}
