@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.areaText {
  font-size: $tokenTypographySupplementaryFontSize;
  line-height: $tokenTypographySupplementaryLineHeight;

  @media (--breakpointMediumFrom) {
    font-size: $tokenTypographyBodyDefaultFontSize;
    line-height: $tokenTypographyBodyDefaultLineHeight;
  }
}
