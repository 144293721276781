@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  align-items: center;
  display: flex;
  flex-direction: column;

  & .supplementaryText {
    margin-bottom: $tokenSpacingLarge1X;
    padding: $tokenSpacingSmall2X 0 0;

    @media (--mediumFromViewport) {
      margin-bottom: $tokenSpacingLarge2X;
    }
  }
}
