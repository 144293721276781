@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  display: flex;
  flex-direction: column;
  row-gap: $tokenSpacingDefault;

  & .ratesCard {
    border: $tokenBorderWidthSmall1X solid $tokenColorsGreyscaleLight4X;
    border-radius: $tokenBorderRadiusDefault;
    padding: $tokenSpacingDefault;
    width: 100%;

    & .content {
      display: flex;
      gap: $tokenSpacingDefault;

      & .icon {
        color: $tokenColorsGreyscaleDark1X;
        display: flex;

        & .iconWrapper {
          align-items: center;
          display: flex;
          height: $tokenSpacingLarge3X;
          width: $tokenSpacingLarge2X;
        }
      }

      & .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: $tokenSpacingDefault;

        & .infoRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          min-height: $tokenSpacingLarge2X;

          & .infoDescription {
            display: flex;
            flex-direction: column;
            justify-content: center;

            & .serviceTitle {
              align-items: center;
              color: $tokenColorsGreyscaleDark3X;
            }

            & .title {
              align-items: center;
              color: $tokenColorsGreyscaleDark3X;
              display: flex;
              font-size: $tokenFontSizesSmall1X;
              gap: $tokenSpacingSmall3X;
              line-height: $tokenLineHeightsSmall1X;

              & svg {
                height: $tokenSpacingDefault;
                width: $tokenSpacingDefault;
              }
            }

            & .description {
              color: $tokenColorsGreyscaleDark2X;
              font-size: $tokenTypographyFootnoteFontSize;
              line-height: $tokenLineHeightsSmall1X;
            }
          }

          & .infoPrice {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            justify-content: center;
            padding-left: $tokenSpacingDefault;
            text-align: right;

            & .timeUnit {
              color: $tokenColorsGreyscaleDark2X;
              font-size: $tokenTypographyFootnoteFontSize;
              line-height: $tokenLineHeightsSmall1X;
            }
          }
        }
      }
    }
  }
}
