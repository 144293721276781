@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --mediumFromViewport (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --largeFromViewport (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  display: grid;
  gap: $tokenSpacingDefault;

  @media (--mediumFromViewport) {
    gap: $tokenSpacingLarge1X;
  }

  & .experienceWrapper {
    grid-gap: $tokenSpacingSmall2X;

    & svg {
      color: $tokenColorsBrandDark1X;
    }
  }

  & .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & .listItem {
      align-items: start;
      display: grid;
      font-size: $tokenFontSizesSmall1X;
      gap: $tokenSpacingSmall2X;
      grid-template-columns: auto 1fr;

      @media (--mediumFromViewport) {
        font-size: $tokenFontSizesDefault;
      }

      @media (--largeFromViewport) {
        align-items: center;
      }

      & svg {
        padding-top: calc($tokenSpacingSmall4X/2 + $tokenSpacingSmall4X/4);
      }
    }
  }

  & .traitsWrapper {
    grid-gap: $tokenSpacingDefault;

    @media (--mediumFromViewport) {
      grid-row-gap: $tokenSpacingLarge1X;
    }
  }

  & .descriptionWrapper {
    margin-top: -$tokenSpacingSmall1X;

    & p {
      font-size: $tokenFontSizesSmall1X;

      @media (--mediumFromViewport) {
        font-size: $tokenFontSizesDefault;
      }
    }

    & span {
      color: $tokenColorsGreyscaleDark4X;
      font-weight: $tokenFontWeightsMedium;
    }

    & button {
      font-size: $tokenFontSizesSmall1X;
    }
  }

  & .experienceHeading {
    color: $tokenColorsGreyscaleDark4X;
    font-weight: $tokenFontWeightsMedium;
    margin-bottom: $tokenSpacingSmall2X;
  }
}
