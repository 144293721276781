@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  & [data-faux-role='formField'] {
    margin-bottom: 0;
  }

  & [data-error-message] {
    position: relative;
  }

  & .notice {
    font-size: $tokenFontSizesSmall1X;
    margin-top: $tokenSpacingSmall2X;
    text-align: center;
  }
}
