@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: flex;
  flex-direction: column;

  & .reviewerContent {
    display: flex;
    min-width: 100%;

    & .avatar {
      --borderRadius: 50%;

      border-radius: var(--borderRadius);
      height: $tokenSpacingLarge4X;
      margin-right: $tokenSpacingDefault;
      overflow: hidden;
      width: $tokenSpacingLarge4X;
    }

    & .reviewerInformation {
      align-items: flex-start;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      & .reviewerName {
        color: $tokenColorsGreyscaleDark4X;
        font-weight: $tokenFontWeightsMedium;
      }

      & .reviewServiceAndDate {
        align-items: center;
        color: $tokenColorsGreyscaleDark2X;
        display: flex;
        font-size: $tokenTypographySupplementaryFontSize;
        font-weight: $tokenTypographySupplementaryFontWeight;
        line-height: $tokenTypographySupplementaryLineHeight;
      }

      & .verifiedCustomerPill {
        font-size: $tokenTypographyFootnoteFontSize;
        height: $tokenSpacingLarge1X;
        line-height: $tokenTypographyFootnoteLineHeight;
        width: $tokenSpacingLarge9X;

        @media (--breakpointMediumFrom) {
          font-size: $tokenTypographySupplementaryFontSize;
          height: calc($tokenSpacingLarge1X + $tokenSpacingSmall2X);
          line-height: $tokenTypographySupplementaryLineHeight;
          width: calc($tokenSpacingLarge9X + $tokenSpacingDefault);
        }
      }
    }
  }

  & .ratingAndDescription {
    margin: $tokenSpacingLarge1X 0 0;
  }

  & .rating {
    margin-bottom: $tokenSpacingSmall2X;
  }

  & .description {
    font-size: $tokenTypographySupplementaryFontSize;
    font-weight: $tokenTypographySupplementaryFontWeight;
    line-height: $tokenTypographySupplementaryLineHeight;

    @media (--breakpointMediumFrom) {
      font-size: $tokenTypographyBodyDefaultFontSize;
      line-height: $tokenTypographyBodyDefaultLineHeight;
    }
  }
}
