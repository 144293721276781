@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  position: relative;

  & input:not(:focus, :disabled) {
    $borderWidth: $tokenBorderWidthElementFormInputDefault;
    $paddingSides: $tokenSpacingElementFormTextInputPadding;
    $paddingSidesHover: calc($paddingSides - $borderWidth);

    --borderColor: $tokenColorsStatusError;
    --borderWidth: $tokenBorderWidthElementFormInputHover;
    --paddingSides: $paddingSidesHover;

    &::placeholder {
      color: $tokenColorsStatusError;
      opacity: 1;
    }
  }

  & svg {
    color: $tokenColorsStatusError;
  }

  & .error {
    color: $tokenColorsStatusError;
    font-size: $tokenFontSizesElementFormValidationMessage;
    margin-top: $tokenSpacingSmall2X;
  }
}
