@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: grid;
  grid-template-columns: 1fr;

  /*
  for having some space above when using anchor link
  navigating to the component id=customer-reviews
   */
  margin-top: -$tokenSpacingLarge1X;
  padding-top: $tokenSpacingLarge1X;

  @media (--breakpointMediumFrom) {
    grid-template-columns: 1fr 2fr;

    /*
    for having some space above when using anchor link
    navigating to the component id=customer-reviews
    */
    margin-top: calc(-$tokenSpacingSmall1X * 10);
    padding-top: calc($tokenSpacingSmall1X * 10);
  }

  & .averageRatingAndTotalReviews {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: $tokenSpacingSmall4X;

    & svg {
      color: $tokenColorsOtherRating;
    }

    & .averageRating {
      margin-left: $tokenSpacingSmall2X;
    }
  }

  & .loading {
    display: flex;
    justify-content: center;
  }

  & .noReview {
    background-color: $tokenColorsBrandTertiaryDefault;
    border-radius: $tokenBorderRadiusElementFormInput;
    padding: $tokenSpacingDefault;
  }

  & .divider {
    @media (--breakpointMediumFrom) {
      grid-column: span 2;
    }
  }
}
