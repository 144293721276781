@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointLargeFrom (min-width: $tokenSizingBreakpointLarge1XFrom);
@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  --iconwidth: $tokenSpacingLarge2X;

  margin: $tokenSpacingLarge2X 0;

  &:first-child {
    margin-top: 0;
  }

  & .question {
    border-radius: $tokenBorderRadiusDefault;
    color: $tokenColorsBrandDefault;
    display: grid;
    font-size: $tokenFontSizesLarge1X;
    font-weight: $tokenFontWeightsSemiBold;
    grid-gap: $tokenSpacingSmall2X;
    grid-template-columns: 1fr var(--iconwidth);
    width: 100%;

    &:hover,
    &:focus:not(:focus-within) {
      color: $tokenColorsInteractionHover;

      & .icon {
        background-color: $tokenColorsBrandLight4X;
        color: $tokenColorsBrandDefault;
      }
    }

    @media (--breakpointLargeFrom) {
      font-size: $tokenFontSizesLarge2X;

      &:active {
        color: $tokenColorsInteractionActive;

        & .icon {
          background-color: $tokenColorsBrandLight3X;
          color: $tokenColorsBrandDefault;
        }
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & .icon {
      --iconwidth: $tokenSpacingLarge1X;

      align-items: center;
      background-color: $tokenColorsBrandLight5X;
      border-radius: calc(var(--iconwidth) / 2);
      display: flex;
      height: var(--iconwidth);
      justify-content: center;
      width: var(--iconwidth);

      @media (--breakpointLargeFrom) {
        --iconwidth: $tokenSpacingLarge2X;
      }

      & svg {
        height: $tokenSpacingSmall1X;
        width: $tokenSpacingSmall1X;

        @media (--breakpointLargeFrom) {
          height: unset;
          width: unset;
        }
      }
    }
  }

  & .answers {
    padding-right: var(--iconwidth);

    & p {
      font-size: $tokenFontSizesDefault;
      margin-bottom: $tokenSpacingLarge2X;
      margin-top: $tokenSpacingDefault;

      @media (--breakpointLargeFrom) {
        font-size: $tokenFontSizesLarge1X;
      }
    }
  }
}
