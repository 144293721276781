@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  --maxHeight: calc(600 * (4 / 5));

  background-color: $tokenColorsGreyscaleLight4X;
  max-height: var(--maxHeight);
  overflow: hidden;
  position: relative;

  & img {
    aspect-ratio: 5 / 4;
    cursor: pointer;
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  & .button {
    background-color: $tokenColorsGreyscaleWhite;
    border-radius: $tokenSpacingSmall2X;
    bottom: calc($tokenSpacingSmall2X + $tokenSpacingSmall4X);
    color: $tokenColorsGreyscaleDark4X;
    font-size: $tokenFontSizesSmall1X;
    padding: $tokenSpacingSmall3X $tokenSpacingSmall1X;
    position: absolute;
    right: $tokenSpacingSmall2X;
  }

  & .actions {
    display: flex;
    justify-content: space-between;
    padding: $tokenSpacingDefault;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
