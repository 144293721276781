@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  --borderRadius: 50%;

  background-color: $tokenColorsGreyscaleLight2X;
  border-radius: var(--borderRadius);
  height: calc($tokenSpacingSmall3X + $tokenSpacingSmall4X);
  margin: 0 $tokenSpacingSmall2X;
  width: calc($tokenSpacingSmall3X + $tokenSpacingSmall4X);
}
