@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  border: $tokenBorderWidthSmall1X solid $tokenColorsGreyscaleLight4X;
  border-radius: $tokenBorderRadiusLarge2X;
  box-shadow: 0 $tokenSpacingSmall4X $tokenSpacingSmall1X 0 $tokenColorsGreyscaleLight4X;
  display: flex;
  flex-direction: column;
  padding: $tokenSpacingSmall1X $tokenSpacingLarge1X $tokenSpacingLarge1X;
}
